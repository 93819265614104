<template>
  <div class="admin">
    <Navbar />
    <section class="mb-5 pt-5">
      <div class="container">
        <HeadSection txt="เข้าสู่ระบบ" />

        <form @submit.prevent="sign_in">
          <div class="row justify-content-center">
            <div class="col-md-4 mb-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="floatingUserName"
                  v-model="form.email"
                  required
                />
                <label for="floatingUserName">ชื่อผู้ใช้</label>
              </div>
            </div>
            <div class="w-100"></div>
            <div class="col-md-4 mb-4">
              <div class="form-floating">
                <input
                  type="password"
                  class="form-control"
                  id="floatingPassword"
                  v-model="form.password"
                  required
                />
                <label for="floatingPassword">รหัสผ่าน</label>
              </div>
            </div>
            <div class="w-100"></div>
            <div class="col text-center">
              <button type="submit" class="btn btn-primary mb-2">เข้าสู่ระบบ</button>
            </div>
          </div>
        </form>
        <p class="text-danger">{{ text }}</p>
      </div>

      <div id="firebaseui-auth-container"></div>
    </section>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import HeadSection from "@/components/HeadSection.vue";
import md5 from "crypto-js/md5";
// import firebase from "firebase/app";
// import * as firebaseui from "firebaseui";
// import "firebaseui/dist/firebaseui.css";

export default {
  name: "admin",
  components: {
    Navbar,
    HeadSection,
  },
  data: function () {
    return {
      form: { email: null, password: "" },
      hash: "",
      result_hash: "d384aef903aeaa8032dc0aa0c418954c",
      text: "",
    };
  },
  methods: {
    sign_in: function (e) {
      e.submitter.innerText = "กรุณารอซักครู่";
      e.submitter.disabled = true;

      this.hash = md5(this.form.password).toString();
      if (
        this.hash == this.result_hash &&
        (this.form.email == "admin" || this.form.email == "Admin")
      ) {
        console.log("Sign In!!!");
        this.$store.state.token = "d384aef903aeaa8032dc0aa0c418954c";
        window.localStorage.setItem(
          "token",
          "d384aef903aeaa8032dc0aa0c418954c"
        );
        this.$router.push("dashboard");
      } else {
        e.submitter.innerText = "เข้าสู่ระบบ";
        e.submitter.disabled = false;
        this.text="อีเมลล์ หรือ รหัสผ่านผิด"
      }
    },
  },
};
</script>

<style>
</style>